import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-user-confirmation-popup',
  templateUrl: './user-confirmation-popup.component.html',
  styleUrls: ['./user-confirmation-popup.component.scss']
})
export class UserConfirmationPopupComponent implements OnInit {


  constructor(public dialogRef: MatDialogRef<UserConfirmationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) { }



  result:boolean = false;

  ngOnInit() {
  }

  public onConfirm(){
    this.result = true;
    this.dialogRef.close(this.result);
  }

}
