// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl:'https://api.develop.alcarritoo.com/api/v2',
  firebaseConfig:{
    apiKey: "AIzaSyDR98Gx-BPsIWnejGg2EM-4o3a7QwsEGpc",
    authDomain: "pediloya-smart.firebaseapp.com",
    databaseURL: "https://pediloya-smart.firebaseio.com",
    projectId: "pediloya-smart",
    storageBucket: "pediloya-smart.appspot.com",
    messagingSenderId: "650035196907",
    appId: "1:650035196907:web:167b38a3291c57f85e5fae",
    measurementId: "G-B5BTZX2HER"
  },
  suscriber: {
    title: "Al carrito",
    dns: 'alcarritoo'
  }
};

