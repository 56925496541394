export interface BaseResponse{
  success:boolean;
  pagination:Pagination;
}

export interface Pagination{
  page:number;
  perPage:number;
  totalItems:number;
  totalPages:number;
}

export class PaginationParams{
  page:number;
  search:string;
  order:string;
  typeOrder:string;
  paginate:number | boolean;
  customParam1:string;
  customParam2:string;
  customParam3:string;
  customParam4:string;
  constructor(page?:number,search?:string,order?:string,typeOrder?:string,paginate?:number | boolean,customParam1?:string,customParam2?:string,customParam3?:string,customParam4?:string){
    this.page = page;
    this.search = search;
    this.order = order;
    this.typeOrder = typeOrder;
    this.paginate = paginate;
    this.customParam1 = customParam1;
    this.customParam2 = customParam2;
    this.customParam3 = customParam3;
    this.customParam4 = customParam4;
  }
}

export class OrdersParams {
  name:string;
  value:any;
}
