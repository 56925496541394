export const PATTERNS = {
  documento: /[0-9]{0,8}/,
  cuil: /([0-9]{2})-([0-9]{8})-([0-9]{1})/i,
  nombres: /[a-záéíóúùñÑ ]{0,50}/i,
  telefono: /([0-9]{2}[0-9]{4}[0-9]{4})/,
  string: /[a-záéíóúùñÑ\s]/i,
  number: /^([0-9])*$/,
  date: /[0-9\/]/,
  currency: /^\D?\s*\d*([.]?\d)*[.]?\d{1}$/,
  email: /([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})/,
  codigoPostal: /[a-zA-Z0-9]/,
  calles: /[a-záéíóúùñÑ0-9\s]/i,
  cbu: /([0-9]{22})/,
  aliasCbu: /[a-z0-9\.\-]{6,20}/i,
  specialCharacters : /^[a-zA-Z0-9!\-\[\],.' ]+$/,
};
