import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'hammerjs';

if (environment.production) {
  enableProdMode();
} 

function setSuscriberData(){
  const link = document.createElement('link');
  link.rel="icon" 
  link.type="image/x-icon" 
  link.href= `./assets/images/${environment.suscriber.dns}-favicon.png` 
  const title = document.createElement('title')
  title.innerHTML = `${environment.suscriber.title}`
  document.head.appendChild(title);
  document.head.appendChild(link);
}
setSuscriberData()

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
