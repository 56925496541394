import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ORDER_PAGES } from '../../constants/utils.constant';
import { PaginationParams } from '../../../entities/base-api-response.entity';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {

  searchText: string;
  activeSearch: boolean;

  orderTypeText: string;
  activeOrderType: boolean;

  orderText: string;
  activeOrder: boolean;

  customOrderText: string;
  activeOrderCustom: boolean;

  customOrderText2: string;
  activeOrderCustom2: boolean;

  customOrderText3: string;
  activeOrderCustom3: boolean;

  customOrderText4: string;
  activeOrderCustom4: boolean;


  orderTypeList = ORDER_PAGES;

  @Output() search = new EventEmitter<any>();
  @Input() searchTextActive: boolean = true;
  @Input() orderList;
  @Input() orderConditional: boolean = true;
  @Input() orderTypeConditional: boolean = true;
  @Input() customOrder: string;
  @Input() customOrderList;
  @Input() mini: boolean = false;

  //rubros
  @Input() customOrder2: string;
  @Input() customOrderList2;

  //categorias
  @Input() customOrder3: string;
  @Input() customOrderList3;

  //etiquetas
  @Input() customOrder4: string;
  @Input() customOrderList4;

  constructor() { }

  ngOnInit() {
  }

  public searchAction() {
    if (this.searchText) {
      this.activeSearch = true;
    }
    if (this.orderTypeText) {
      this.activeOrderType = true;
    }
    if (this.orderText) {
      this.activeOrder = true;
    }
    if (this.customOrderText) {
      this.activeOrderCustom = true;
    }
    if (this.customOrderText2) {
      this.activeOrderCustom2 = true;
    }
    if (this.customOrderText3) {
      this.activeOrderCustom3 = true;
    }
    if (this.customOrderText4) {
      this.activeOrderCustom4 = true;
    }
    this.conditionalSearch();
  }

  public conditionalSearch() {
    if (this.customOrderText == "-1" || this.customOrderText2 == "-1" || this.customOrderText3 == "-1" || this.customOrderText4 == "-1") {
      if (this.customOrderText == "-1") {
        this.customOrderText = null;
        this.pagination();
      }
      if (this.customOrderText2 == "-1") {
        this.customOrderText2 = null;
        this.pagination();
      }
      if (this.customOrderText3 == "-1") {
        this.customOrderText3 = null;
        this.pagination();
      }
      if (this.customOrderText4 == "-1") {
        this.customOrderText4 = null;
        this.pagination();
      }
    } else {
      this.pagination();
    }

  }

  public pagination() {
    const pagination = new PaginationParams(1, this.searchText, this.orderText, this.orderTypeText, null, this.customOrderText, this.customOrderText2, this.customOrderText3, this.customOrderText4);
    this.search.emit(pagination);
  }

  public clearSearch() {
    this.searchText = '';
    this.activeSearch = false;
    this.resetSeach();
  }

  public clearOrderType() {
    this.orderTypeText = '';
    this.activeOrderType = false;
    this.resetSeach();
  }

  public clearOrder() {
    this.orderText = '';
    this.activeOrder = false;
    this.resetSeach();
  }

  public clearCustomOrder() {
    this.customOrderText = '';
    this.activeOrderCustom = false;
    this.resetSeach();
  }

  public clearCustomOrder2() {
    this.customOrderText2 = '';
    this.activeOrderCustom2 = false;
    this.resetSeach();
  }

  public clearCustomOrder3() {
    this.customOrderText3 = '';
    this.activeOrderCustom3 = false;
    this.resetSeach();
  }

  public clearCustomOrder4() {
    this.customOrderText4 = '';
    this.activeOrderCustom4 = false;
    this.resetSeach();
  }

  public clearAll() {
    this.clearSearch();
    this.clearOrder();
    this.clearOrderType();
    this.clearCustomOrder();
    this.clearCustomOrder2();
    this.clearCustomOrder3();
    this.clearCustomOrder4();
    this.resetSeach();
  }

  public resetSeach() {
    const pagination = new PaginationParams(
      1, // pagina
      this.searchText, // texto de busqueda
      this.orderText,  // orden por parametro
      this.orderTypeText, // asc o desc
      null, // tiene paginación ?
      this.customOrderText, // parametro custom
      this.customOrderText2, // parametro custom2
      this.customOrderText3, // parametro custom3
      this.customOrderText4 //parametro custom4
    );
    this.search.emit(pagination);
  }

}
