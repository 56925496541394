export const LABELS = {
  LOGO:{
    LOGO_ALT:'Al carrito logo',
  },
  PAGINATOR :{
      COUNT:'Items por pagina',
      NEXT:'Proxima Página',
      PREVIOUS:'Página Anterior',
      FIRST:'Primera Página',
      LAST:'Ultima Página'
  }
}
