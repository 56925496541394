
import { Component } from '@angular/core';
import { LoadingSpinnerService } from '../services/loading-spinner/loading-spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isLoading:boolean;
  constructor(
    public loading:LoadingSpinnerService,
  ){
    this.loading.isLoading$.subscribe(load => {
      this.isLoading = load;
    })
  }





}


