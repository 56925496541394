import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MenuItems } from './menu-items/menu-items';
import {
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective
} from './accordion';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material-module';
import { PipeModule } from '../pipes/pipes.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PaginationComponent } from './pagination/pagination.component';
import { SearchInputComponent } from './search-input/search-input.component';

@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    PaginationComponent,
    SearchInputComponent
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    PipeModule,
    NgxSkeletonLoaderModule,
    PaginationComponent,
    SearchInputComponent
  ],
  imports: [FormsModule
    , CommonModule
    , RouterModule,
    MaterialModule,
    PipeModule,
    NgxSkeletonLoaderModule
  ],
  providers: [MenuItems]
})
export class SharedModule { }
