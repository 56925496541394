
import { Component, Injectable } from "@angular/core";
import { ConfirmationPopupComponent } from "./components/confirmation-popup/confirmation-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { ICONS } from "./constants/icons";
import { UserConfirmationPopupComponent } from "./components/user-confirmation-popup/user-confirmation-popup.component";
import { DetalleGeneralComponent, itemGeneral } from './components/detalle-general/detalle-general.component';

@Injectable()
export class PopUpsUtils {

  constructor(public dialog: MatDialog){}

  public popUpConfirmation(text: string, error: boolean = false) {
    let popup;
    if (!error) {
      popup = this.dialog.open(ConfirmationPopupComponent, {
        disableClose:true,
        data: {
          icon: ICONS.SUCCESS,
          text: text,
          error: false
        },
        panelClass: ['classic-modal', 'litle-modal'],
      })
    } else {
      popup = this.dialog.open(ConfirmationPopupComponent, {
        disableClose:true,
        data: {
          icon: ICONS.ERROR,
          text: text,
          error: true
        },
        panelClass: ['classic-modal', 'litle-modal'],
      })
    }
    return popup;
  }


  public popUpUserConfirm(text:string){
    const popup = this.dialog.open(UserConfirmationPopupComponent, {
      disableClose:true,
      data: {
        error: false,
        text: text
      },
      panelClass: ['classic-modal', 'litle-modal'],
    })
    return popup;
  }

  public popUpDetails(title:string,items:itemGeneral[]){
    const popup = this.dialog.open(DetalleGeneralComponent, {
      disableClose:true,
      panelClass: ['classic-modal','mid-modal'],
      data: {
        title:title,
        items:items,
      }
    })
  }



}
