import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { API_ENDPOINTS_COMERCIOS, API_ENDPOINTS_COMERCIOS_LIST } from 'app/constants/api.constant';
import { Comercio } from 'entities/comercios.entity';
import { SigninUser } from 'entities/user.entity';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from 'services/http';
import { LocalstorageService } from 'services/localstorage/localstorage.service';
import { LoginService } from 'services/login/login.service';
import { UserService } from 'services/user/user.service';
import { ConfiguracionComercio } from '../../entities/comercios.entity';

@Injectable({
  providedIn: 'root'
})
export class ComerciosService extends HttpService implements OnInit {

  private userId: number;
  public userSignin$: BehaviorSubject<SigninUser> = new BehaviorSubject(null);
  public comercioData$: BehaviorSubject<ConfiguracionComercio> = new BehaviorSubject(null);
  idRubroComercial: number = this.userSignin.getIdRubroComercial();
  idSuscriptor: number = this.userSignin.getIdSuscriptor();

  constructor(
    protected http: HttpClient,
    protected auth: LoginService,
    private localStorageService: LocalstorageService,
    public router: Router,
    protected userSignin: UserService,
  ) {
    super(http, auth);
  }

  ngOnInit() { }

  public setComercioData(comercio:ConfiguracionComercio){
    this.comercioData$.next(comercio);
  }

  public getComercios() {
    return this.get<any>(API_ENDPOINTS_COMERCIOS_LIST(this.idSuscriptor));
  }

  public getComercio(){
    return this.get<any>(API_ENDPOINTS_COMERCIOS(this.idSuscriptor,this.idRubroComercial));
  }

  public getComercioConfig(){
    return this.get<any>(API_ENDPOINTS_COMERCIOS(this.idSuscriptor,this.idRubroComercial,'config'));
  }



}
