import { UserService } from './../../../../services/user/user.service';
import { ComerciosService } from './../../../../services/comercios/comercios.service';
import { Subscription } from 'rxjs';
import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  HostListener,
  Directive,
  AfterViewInit,
  EventEmitter,
  Output
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class AppSidebarComponent implements OnInit, OnDestroy {
  public config: PerfectScrollbarConfigInterface = {};
  @Output() sidenavClose = new EventEmitter();
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;
  public logoComercio: string; 
  private subscriptions = new Subscription();

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private comercioService: ComerciosService,
    private userService: UserService,
    public menuItems: MenuItems
  ) {
    this.mobileQuery = media.matchMedia('(min-width:1100px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  public onSidenavClose = () => {
    if(window.innerWidth <= 1100){
      this.sidenavClose.emit();
    }
  }

  ngOnInit(): void {
    this.subscriptions.add(this.comercioService.comercioData$.subscribe(comercio => {
      if (comercio?.url_logo) {
        this.logoComercio = comercio.url_logo
      } else {
        this.logoComercio = this.userService.getLogo();
        // if(this.logoComercio){
        //   this.logoComercio = this.logoAlCarrito;
        // }
      }
    }))
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
