import { Directive, Host, HostListener, Input, Optional, SkipSelf } from "@angular/core";
import { PATTERNS } from "../constants/pattern.constants";
import { AbstractControl, ControlContainer } from '@angular/forms';

export function isNumber(e: KeyboardEvent): boolean {
  return PATTERNS.number.test(e.key);
}

@Directive({
  selector:'[inputNumber]'
})
export class InputNumberDirective{
  private allowedKeys = [8,9,13,37,38,39,40,46];
  @HostListener('keydown',['$event'])
  setLimit(event:any){
    if(!isNumber(event)){
      if(!this.allowedKeys.includes(event.keyCode)){
        event.preventDefault();
        event.stopPropagation();
      }
      if(event.keyCode === 38 ||event.keyCode === 40){
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }
  constructor(){}
}


@Directive({
  selector:'[baseInput]'
})

export class BaseInputDirective {
  @Input() label:string;
  @Input() errorMsg:string;
  @Input() controlName:string;
  @Input() isDisabled?:boolean;
  @Input() isRequired:boolean;
  public controlAccessor:AbstractControl;

  public onChangeValue = (_:any) => {};
  public onTouched = () => {};

  constructor(@Optional() @Host() @SkipSelf() controlContainer:ControlContainer){}
}

