
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceModule } from '../services/service.module';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { MaterialModule } from './material-module';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { Utils } from './utils';
import { AppBlankComponent } from './layouts/blank/blank.component';

import { LoginComponent } from './layouts/login/login/login.component';
import { FooterComponent } from './layouts/full/footer/footer.component';
import { ConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup.component';
import { PassRecoveryComponent } from './layouts/pass-recovery/pass-recovery.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { InputNumberDirective } from './directives/inputs.directives';
import { UserConfirmationPopupComponent } from './components/user-confirmation-popup/user-confirmation-popup.component';
import { PopUpsUtils } from './pop-ups-utils';
import { DetalleGeneralComponent } from './components/detalle-general/detalle-general.component';
//import { CrearOfertaPdfComponent } from './components/crear-oferta-pdf/crear-oferta-pdf.component';



@NgModule({

  declarations: [
    AppComponent,
    FullComponent,
    AppBlankComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
    LoginComponent,
    FooterComponent,
    ConfirmationPopupComponent,
    UserConfirmationPopupComponent,
    DetalleGeneralComponent,
    PassRecoveryComponent,
    LoadingSpinnerComponent,
    InputNumberDirective,
    //CrearOfertaPdfComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    ServiceModule,
    SharedModule,
    ReactiveFormsModule,
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
    HammerModule,
  ],
  exports:[
    InputNumberDirective
  ],
  providers:[
    Utils,
    PopUpsUtils
  ],

  bootstrap: [AppComponent],
  entryComponents: [ConfirmationPopupComponent,UserConfirmationPopupComponent,DetalleGeneralComponent]//CrearOfertaPdfComponent
})
export class AppModule { }
