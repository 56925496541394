import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PopUpsUtils } from 'app/pop-ups-utils';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: LoginService,
    private router: Router,
    public popUpsUtils: PopUpsUtils,) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let handled: boolean = false;

    return next.handle(request)
      .pipe(
        retry(1),
        catchError((returnedError) => {
          let errorMessage = null;

          if (returnedError.error instanceof ErrorEvent) {
            errorMessage = `Error: ${returnedError.error.message}`;
          } else if (returnedError instanceof HttpErrorResponse) {
            errorMessage = `Error Status ${returnedError.status}: ${returnedError.error.error} - ${returnedError.error.message}`;
            handled = this.handleServerSideError(returnedError);
          }

          console.error(errorMessage ? errorMessage : returnedError);

          if (!handled) {
            if (errorMessage) {
              return throwError(errorMessage);
            } else {
              return throwError("Unexpected problem occurred");
            }
          } else {
            return of(returnedError);
          }
        })
      )
  }

  private handleServerSideError(error: HttpErrorResponse): boolean {
    let handled: boolean = false;
    switch (error.status) {
      case 401:
        this.popUpsUtils.popUpConfirmation('Se venció la sesión.', true);
        this.authenticationService.logout();
        location.reload();
        handled = true;
        break;
      case 403:
        this.popUpsUtils.popUpConfirmation('Acceso denegado.', true);
        this.authenticationService.logout();
        handled = true;
        break;
    }

    return handled;
  }
}
