import { HttpHeaders } from '@angular/common/http';

const subs = 'suscriptores'

// HEADER - AUTH ---------------

export let AUTH_HEADER = (tokenId: any) => {
  let header = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Authorization': `Bearer ${tokenId}`
  })
  return header;
}

export let AUTH_HEADER_FILE_UPDATE = (tokenId: any) => {
  let header = new HttpHeaders({
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Authorization': `Bearer ${tokenId}`
  })
  return header;
}

// USERS -----------------------

export const API_ENDPOINTS_USERS = {
  USERS: '/users',
  USERS_ADRESS: '/users/address',
  USERS_SYSTEM: '/users_system',
  USERS_SYSTEM_SIGNIN: '/users_system/signin',
  USERS_SYSTEM_FUNCTIONS: '/users_system/functions',
}

export const API_ENDPOINTS_USER_SYSTEM = (id_user: number) => {
  let url = (id_user)
  ? `/users_system/${id_user}`
  : `/users_system`;
return url;
}

// PEDIDOS----------------------

export const API_ENDPOINTS_PEDIDOS = (id_suscriptor: any, id_pedido?: any, comercio?: boolean) => {
  let url = (id_pedido)
    ? `/${subs}/${id_suscriptor}/pedidos/${id_pedido}`
    : `/${subs}/${id_suscriptor}/pedidos`;
  return url;
}

// PEDIDO----------------------

export const API_ENDPOINTS_PEDIDO = (id_suscriptor: any, id_comercio?: any, id_user?: any, ) => {
  let url = (id_user)
    ? `/${subs}/${id_suscriptor}/comercios/${id_comercio}/users/${id_user}/pedidos`
    : `/${subs}/${id_suscriptor}/pedidos`;
  return url;
}

// Detalle pedido----------------------

export const API_ENDPOINTS_DETALLE_PEDIDO = (id_suscriptor: any, id_pedido?: any, id_detalle?: any) => {
  let url = (id_pedido)
    ? `/${subs}/${id_suscriptor}/pedidos/${id_pedido}/detalle_productos/${id_detalle}`
    : `/${subs}/${id_suscriptor}/pedidos`;
  return url;
}

// WORKFLOWS----------------------

export const API_ENDPOINTS_WORKFLOW = (id_suscriptor: any, id_workflow_pedido?: any) => {
  let url = (id_workflow_pedido)
    ? `/${subs}/${id_suscriptor}/workflow-de-pedido/${id_workflow_pedido}`  //  info del comercio
    : `/${subs}/${id_suscriptor}/workflow-de-pedido`;   // listado de comercios
  return url;
}

// PRODUCTOS----------------------

export const API_ENDPOINTS_PRODUCTOS = (id_suscriptor: any, id_producto?: any) => {
  let url = (id_producto)
    ? `/${subs}/${id_suscriptor}/productos/${id_producto}`
    : `/${subs}/${id_suscriptor}/productos`;
  return url;
}

// COMERCIOS -------------------

export const API_ENDPOINTS_COMERCIOS_LIST = (id_suscriptor: any) => {
  let url = `/${subs}/${id_suscriptor}/comercios`
  return url;
}

// tslint:disable-next-line: max-line-length
export const API_ENDPOINTS_COMERCIOS = (id_suscriptor: any, id_comercio?: any, item_del_comercio?: string, id_item?: any, sub_item?: string) => {
  let url = (id_comercio)
    ? `/${subs}/${id_suscriptor}/comercios/${id_comercio}`  //  info del comercio
    : `/${subs}/${id_suscriptor}/comercios`;   // listado de comercios
  if (id_comercio && item_del_comercio) {
    url = url + `/${item_del_comercio}`;   // item dentro del comercio
    if (id_item) {
      url = url + `/${id_item}/${sub_item}`; // id item dentro del comercio
    }
  }
  return url;
}

export const API_ENDPOINTS_COMERCIOS_PEDIDOS = (id_suscriptor: any, id_comercio: any) => {
  let url = `/${subs}/${id_suscriptor}/comercios/${id_comercio}/pedidos`;
  return url;
}

// SUCURSALES ------------------

export const API_ENDPOINTS_SUCURSALES = (id_suscriptor: any, id_sucursal?: any) => {
  let url = (id_sucursal)
    ? `/${subs}/${id_suscriptor}/sucursales/${id_sucursal}` //  info de sucursal
    : `/${subs}/${id_suscriptor}/sucursales`; // listado de sucursales
  return url;
}

// ETIQUETAS -------------------

export const API_ENDPOINTS_ETIQUETAS = (id_suscriptor: any, id_etiqueta?: any) => {
  let url = (id_etiqueta)
    ? `/${subs}/${id_suscriptor}/etiquetas/${id_etiqueta}`
    : `/${subs}/${id_suscriptor}/etiquetas`;
  return url;
}

// FORMA PAGO -------------------

export const API_ENDPOINTS_FORMA_PAGO = (id_suscriptor: any, id_forma_pago?: any) => {
  let url = (id_forma_pago)
    ? `/${subs}/${id_suscriptor}/forma-de-pago/${id_forma_pago}`
    : `/${subs}/${id_suscriptor}/forma-de-pago`;
  return url;
}


// VENDEDORES -------------------

export const API_ENDPOINTS_VENDEDORES = (id_suscriptor: any, id_vendedores?: any) => {
  let url = (id_vendedores)
    ? `/${subs}/${id_suscriptor}/vendedores/${id_vendedores}`
    : `/${subs}/${id_suscriptor}/vendedores`;
  return url;
}

// PUNTOS ENTREGA -------------------

export const API_ENDPOINTS_PUNTOS_ENTREGA = (id_suscriptor: any, id_puntos_entregas?: any) => {
  let url = (id_puntos_entregas)
    ? `/${subs}/${id_suscriptor}/puntos-de-entrega/${id_puntos_entregas}`
    : `/${subs}/${id_suscriptor}/puntos-de-entrega`;
  return url;
}

// CATEGORIAS -----------------------


export const API_ENDPOINTS_CATEGORIAS = (id_suscriptor: any, id_categorias?: any) => {
  let url = (id_categorias)
    ? `/${subs}/${id_suscriptor}/categorias/${id_categorias}`
    : `/${subs}/${id_suscriptor}/categorias`;
  return url;
}

// FILES UPLOAD ----------------------

export const API_ENDPOINTS_FILES_UPLOAD = (id_suscriptor: any, folder?: any) => {
  let url = `/${subs}/${id_suscriptor}/uploads/${folder}`;
  return url;
}


// RUBROS ----------------------

export const API_ENDPOINTS_RUBROS = (id_suscriptor: any, id_rubros?: any) => {
  let url = (id_rubros)
    ? `/${subs}/${id_suscriptor}/rubros/${id_rubros}`
    : `/${subs}/${id_suscriptor}/rubros`;
  return url;
}

// FORMA DE PAGO ----------------------

export const API_ENDPOINTS_FORMA_DE_PAGO = (id_suscriptor: any, id_forma_de_pago?: any) => {
  let url = (id_forma_de_pago)
    ? `/${subs}/${id_suscriptor}/forma-de-pago/${id_forma_de_pago}`
    : `/${subs}/${id_suscriptor}/forma-de-pago`;
  return url;
}

// MODOS DE ENTREGA ----------------------

export const API_ENDPOINTS_MODO_DE_ENTREGA = (id_suscriptor: any, id_modo_entrega?: any) => {
  let url = (id_modo_entrega)
    ? `/${subs}/${id_suscriptor}/modo-de-entrega/${id_modo_entrega}`
    : `/${subs}/${id_suscriptor}/modo-de-entrega`;
  return url;
}

// PAISES --------------------------------

export const API_ENDPOINTS_PAISES = '/paises';

// ADDRESSES  ----------------------------


export const API_ENDPOINTS_ADDRESSES = (id_user?: any, id_address?: any) => {
  let url;
  if (id_user) {
    url = `/users/${id_user}/address`;
  } else {
    url = `/users/address/${id_address}`;
  }
  return url;
}

export const API_ENDPOINTS_ADDRESS = (id_address?: any) => {
  let url;
  if (id_address) {
    url = `/users/address/${id_address}`;
  }
  return url;
}


// VALID ADDRESS  ----------------------------


export const API_ENDPOINTS_VALID_ADDRESSES = (search?: string) => {
  let url;
  url = `/validar-direccion/buscar?search=${search}`;
  return url;
}

// CLIENTES  -----------------------------

export const API_ENDPOINTS_CLIENTE = (id_suscriptor: any, id_cliente?: any) => {
  let url = (id_cliente)
    ? `/${subs}/${id_suscriptor}/clientes/${id_cliente}`
    : `/${subs}/${id_suscriptor}/clientes`
  return url;
}

// AVISOS  -----------------------------

export const API_ENDPOINTS_AVISO = (id_suscriptor: any, id_aviso?: any) => {
  let url = (id_aviso)
    ? `/${subs}/${id_suscriptor}/avisos-comercio/${id_aviso}`
    : `/${subs}/${id_suscriptor}/avisos-comercio`
  return url;
}

// AVISOS  -----------------------------

export const API_ENDPOINTS_MENSAJE = (id_suscriptor: any, id_mensaje?: any) => {
  let url = (id_mensaje)
    ? `/${subs}/${id_suscriptor}/mensajes/${id_mensaje}`
    : `/${subs}/${id_suscriptor}/mensajes`
  return url;
}
