import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { HttpService } from '../http';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';
import { first } from 'rxjs/operators';
import { LocalstorageService } from '../localstorage/localstorage.service';
import { SigninUser, UserInfo } from '../../entities/user.entity';
import { API_ENDPOINTS_USERS } from '../../app/constants/api.constant';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})

export class LoginService implements OnDestroy {

  public userFirebaseData$: BehaviorSubject<UserInfo> = new BehaviorSubject(null);
  public tokenId: any;
  private subscriptions = new Subscription();

  constructor(
    protected http: HttpClient,
    public router: Router,
    private afAuth: AngularFireAuth,
    private localStorageService: LocalstorageService,
  ) {

    this.subscriptions.add(this.afAuth.authState.subscribe((user: UserInfo) => {
      if (user) {
        this.userFirebaseData$.next(user);
        this.localStorageService.setLocalStorage('user', JSON.stringify(user));
        JSON.parse(this.localStorageService.getLocalStorage('user'));
      } else {
        this.localStorageService.setLocalStorage('user', null);
        JSON.parse(this.localStorageService.getLocalStorage('user'));
      }
    }, () => {
      this.router.navigate(['login']);
    })
    )
    this.subscriptions.add(
      this.afAuth.idToken.subscribe(idToken => {
        if (idToken) {
          this.tokenId = idToken;
          if(!environment.production){
            console.log(idToken)
          }
        }
      })
    )
  }

  ngOnDestroy(){
    this.subscriptions.unsubscribe();
  }

  // Login

  async login(email: string, password: string) {
    try {
      const result = await this.afAuth.signInWithEmailAndPassword(
        email,
        password
      )
      await this.afAuth.setPersistence('session');
      return result;
    }
    catch (error) {
      console.log(error);
    }
  }

  // Logout

  async logout() {
    try {
      const result = await this.afAuth.signOut();
      this.localStorageService.deleteLocalStorage('user');
      this.localStorageService.deleteLocalStorage('userSignin');
      this.router.navigate(['login']);
      return result;
    }
    catch (error) {
      console.log(error);
    }
  }

  // get user

  public getCurrentUser() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  public getUser() {
    return this.userFirebaseData$.asObservable();
  }

  public getUserLocalStorage() {
    const user = JSON.parse(this.localStorageService.getLocalStorage('user'));
    return (user !== null) ? user : false;
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(this.localStorageService.getLocalStorage('user'));
    return (user !== null) ? true : false;
  }

  get idToken(): any {
    return this.tokenId;
  }

  public async passwordRecovery(email: string): Promise<void> {
    try {
      const result = await this.afAuth.sendPasswordResetEmail(email);
      return result;
    }
    catch (error) {
      console.log(error);
    }
  }

  public async passwordChange(password: any): Promise<void> {
    try {
      const result = (await this.afAuth.currentUser).updatePassword(password);
      return result;
    }
    catch (error) {
      console.log(error);
    }
  }





}
