import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Injectable } from "@angular/core";
import { PaginationParams } from '../entities/base-api-response.entity';
import * as moment from 'moment';


@Injectable()
export class Utils {

  constructor() { }
  // mensajes de error de los inputs

  public getErrorMessage(field: FormControl, name: string) {
    if (field.hasError('required')) {
      return `El campo ${name} es requerido`;
    }
  }

  public getEmailErrorMessage(field: FormControl) {
    if (field.hasError('email')) {
      return `El formato de Email es incorrecto`;
    }
  }

  public getNumberErrorMessage(field: FormControl) {
    if (field.hasError('pattern')) {
      return `El formato debe ser numerico`;
    }
  }

  public getAutoCompleteErrorMessage(field: FormControl) {
    if (field.hasError('match')) {
      return `Debe seleccionar una opcion del listado`;
    }
  }

  public getMaxSizeImgErrorMessage(field: FormControl, limite: string) {
    if (field.hasError('fileSize')) {
      return `Una de las imagenes supera el limite de ${limite} permitidos`;
    }
  }

  public valueSelected(myArray: any[], parameter: string): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      let pickedOrNot = [];
      let selectboxValue = c.value;
      if (selectboxValue && (selectboxValue[parameter] || selectboxValue)) {
        pickedOrNot = myArray.filter(
          (alias) => {
            return alias[parameter] === selectboxValue[parameter] || alias[parameter] === selectboxValue
          }
        );
      }
      if (pickedOrNot.length > 0) {
        // everything's fine. return no error. therefore it's null.
        return null;
      } else {
        //there's no matching selectboxvalue selected. so return match error.
        return { match: true };
      }
    };
  }

  // urls paginados

  public buildUrl(url: string, pagination?: PaginationParams, customName1?: string, customName2?: string, customName3?: string, customName4?: string) {
    let params: boolean = false;
    if (pagination && (pagination.search
                        || pagination.order
                        || pagination.typeOrder
                        || pagination.page
                        || pagination.paginate
                        || pagination.customParam1
                        || pagination.customParam2
                        || pagination.customParam3
                        || pagination.customParam4)) {
      if (pagination.search) {
        (params) ? url = url + '&' : url = url + '?';
        url = url + 'search=' + pagination.search;
        params = true;
      }
      if (pagination.order) {
        (params) ? url = url + '&' : url = url + '?';
        url = url + 'order=' + pagination.order;
        params = true;
      }
      if (pagination.typeOrder) {
        (params) ? url = url + '&' : url = url + '?';
        url = url + 'typeOrder=' + pagination.typeOrder;
        params = true;
      }
      if (pagination.page) {
        (params) ? url = url + '&' : url = url + '?';
        url = url + 'page=' + pagination.page;
        params = true;
      }
      if (pagination.paginate) {
        (params) ? url = url + '&' : url = url + '?';
        url = url + 'paginate=' + pagination.paginate;
        params = true;
      }
      if (pagination.customParam1) {
        (params) ? url = url + '&' : url = url + '?';
        url = url + `${customName1}=` + pagination.customParam1;
        params = true;
      }
      if (pagination.customParam2) {
        (params) ? url = url + '&' : url = url + '?';
        url = url + `${customName2}=` + pagination.customParam2;
        params = true;
      }
      if (pagination.customParam3) {
        (params) ? url = url + '&' : url = url + '?';
        url = url + `${customName3}=` + pagination.customParam3;
        params = true;
      }
      if (pagination.customParam4) {
        (params) ? url = url + '&' : url = url + '?';
        url = url + `${customName4}=` + pagination.customParam4;
        params = true;
      }
    }
    return url;
  }

  public splitImgsUrl(url, separator = ','): any {
    let newArray = url.split(separator);
    return newArray;
  }

  public

  public arrayToString(dataArray: any[]): string {
    let newStringData = dataArray.toString();
    return newStringData;
  }

  // formato horario

  public timeFormat(time: string) {
    return moment(time).format('DD/MM/YYYY');
  }

  // cortar texto con 3 puntos

  public cutLongText(text: string, long = 15, respectSpace = true) {
    if (text.length > long) {
      text = text.substring(0, long);
      if (respectSpace) {
        let last = text.lastIndexOf(" ");
        text = text.substring(0, last);
      } else {
        text = text.substring(0, long);
      }
      text = text + `...`;
    }
    return text;
  }


}
