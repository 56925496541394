import { IMAGES } from './path';
import { environment } from '../../environments/environment.prod';


export const ICONS = {
  SUCCESS: 'done_all',
  ERROR: 'clear'
}

export const DEFAULTS_SELECT_ICONS = [
  { value: IMAGES.ICONS_DEFAULT + '/almuerzo.png', title: 'Almuerzo' },
  { value: IMAGES.ICONS_DEFAULT + '/auriculares.png', title: 'Auriculares' },
  { value: IMAGES.ICONS_DEFAULT + '/bebida.png', title: 'Bebida' },
  { value: IMAGES.ICONS_DEFAULT + '/comida.png', title: 'Comida' },
  { value: IMAGES.ICONS_DEFAULT + '/cubiertos.png', title: 'Cubiertos' },
  { value: IMAGES.ICONS_DEFAULT + '/etiqueta.png', title: 'Etiqueta' },
  { value: IMAGES.ICONS_DEFAULT + '/herramientas.png', title: 'Herramientas' },
  { value: IMAGES.ICONS_DEFAULT + '/laptop.png', title: 'Laptop' },
  { value: IMAGES.ICONS_DEFAULT + '/medialuna.png', title: 'Medialuna' },
  { value: IMAGES.ICONS_DEFAULT + '/reloj.png', title: 'Reloj' },
  { value: IMAGES.ICONS_DEFAULT + '/tablet.png', title: 'Tablet' },
]
