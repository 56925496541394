import { environment } from 'environments/environment';
import { UserService } from 'services/user/user.service';
import { Component, OnInit } from '@angular/core';
import { IMAGES } from '../../../constants/path';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  copyRight:string;
  // logoUrl:string = IMAGES.LOGOURL2;
  logoUrl: string = `./assets/images/${environment.suscriber.dns}.svg`
  suscriptorData: any = this.userSignin.getSuscriptorData();
  suscriptorName: string
  suscriptorLogo: string

  constructor(
    private userSignin: UserService 
  ) { }

  ngOnInit() {
    // console.log(this.suscriptorData)
    this.setCopyRight();
  }

  public setCopyRight(){
    this.suscriptorName = environment.suscriber.title
    this.suscriptorLogo = this.logoUrl
    let year = new Date().getFullYear();
    this.copyRight = `Copyright © ${this.suscriptorName} ${year}`;
  }

}
