import { Injectable } from '@angular/core';
import { UserService } from '../../../services/user/user.service';
import { Functions } from '../../../entities/user.entity';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: 'pedidos', // nombre de la url
    name: 'Pedidos', // texto del item
    type: 'link', // el tipo ( link --> url directa )
    icon: 'assignment' //
  },
  {
    state: 'productos',
    name: 'Productos',
    type: 'sub', //el tipo ( sub --> dropdown )
    icon: 'shopping_bag',
    children: [ // en caso de ser un dropdown
      { state: 'articulos', name: 'Articulos' },
      { state: 'etiquetas', name: 'Etiquetas' }
    ]
  },
  {
    state: 'categorias', // nombre de la url
    name: 'Categorias', // texto del item
    type: 'link', // el tipo ( link --> url directa )
    icon: 'list' //
  },
  {
    state: 'rubros', // nombre de la url
    name: 'Rubros', // texto del item
    type: 'link', // el tipo ( link --> url directa )
    icon: 'source' //
  },
  {
    state: 'forma-de-pago', // nombre de la url
    name: 'Forma de pago', // texto del item
    type: 'link', // el tipo ( link --> url directa )
    icon: 'attach_money' //
  },
  {
    state: 'modo-de-entrega', // nombre de la url
    name: 'Modo de entrega', // texto del item
    type: 'link', // el tipo ( link --> url directa )
    icon: 'local_shipping' //
  },
  {
    state: 'puntos-de-entrega', // nombre de la url
    name: 'Puntos de Entrega', // texto del item
    type: 'link', // el tipo ( link --> url directa )
    icon: 'fmd_good' //
  },
  {
    state: 'vendedores', // nombre de la url
    name: 'Vendedores', // texto del item
    type: 'link', // el tipo ( link --> url directa )
    icon: 'people_alt' //
  },
  {
    state: 'sucursales', // nombre de la url
    name: 'Sucursales', // texto del item
    type: 'link', // el tipo ( link --> url directa )
    icon: 'business' //
  },

];

@Injectable()
export class MenuItems {
  constructor(protected userSignin: UserService, ){

  }
  getMenuitem(): Functions[] {
    return this.userSignin.getFunctions();
  }
}
