import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IMAGES } from '../../constants/path';
import { LABELS } from '../../constants/labels';
import { Utils } from '../../utils';
import { LoginService } from '../../../services/login/login.service';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { ICONS } from '../../constants/icons';
import { LoadingSpinnerService } from '../../../services/loading-spinner/loading-spinner.service';
import { PopUpsUtils } from '../../pop-ups-utils';

@Component({
  selector: 'app-pass-recovery',
  templateUrl: './pass-recovery.component.html',
  styleUrls: ['./pass-recovery.component.scss']
})
export class PassRecoveryComponent implements OnInit {

  logoUrl: string = IMAGES.LOGOURL;
  logoAlt: string = LABELS.LOGO.LOGO_ALT;
  recoveryForm = new FormGroup({
    email: new FormControl(null, [Validators.required]),
  });

  emailLabel: string = 'Email';
  emailPlaceholder: string = 'Ingresa tu Email';
  constructor(
    private router: Router,
    public utils: Utils,
    private loginService: LoginService,
    public dialog: MatDialog,
    public loading:LoadingSpinnerService,
    private popUpsUtils:PopUpsUtils
  ) { }

  ngOnInit() {
  }

  public async recoveryPass() {
    this.loading.setLoading(true);
    try {
      const email = this.recoveryForm.get('email').value;
      if (email) {
        await this.loginService.passwordRecovery(email);
        this.loading.setLoading(false)
        const popup = this.popUpsUtils.popUpConfirmation('Revisa tu email para cambiar la contraseña.');
        popup.afterClosed().subscribe(result => {
          this.router.navigate(['/login'])
        });

      }
    } catch (error) {
      console.log(error);
    }
  }

}
