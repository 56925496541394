import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../login/login.service';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import { UserService } from '../user/user.service';
import { switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})


export class LoginGuard implements CanActivate {

  constructor(public loginService: LoginService, public router: Router, private afAuth: AngularFireAuth, private userService: UserService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let signinCondition = this.userService.userSignin$.getValue();
    return this.afAuth.authState
    .take(1)
    .map((authState: any) => !!authState)
    .do(authenticated => {
      if (!authenticated || !signinCondition) {
        this.router.navigate(['/login'])
      };
    });
  }


}
