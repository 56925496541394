import { NgModule } from '@angular/core';
import { FilesDownloader } from './files-downloader/filesDownloader.service';
import {AngularFireAuthModule} from '@angular/fire/auth'
import {AngularFireModule} from '@angular/fire'
// Guards
import { LoginGuard } from './guards/login.guard';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../environments/environment';
import { HttpConfigInterceptor } from './http-config-interceptor/http-config-interceptor.service';



@NgModule({
  imports: [
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
  ],
  providers: [
    FilesDownloader,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ]
})

export class ServiceModule{};
