import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerService {

  public isLoading$:BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  public setLoading(data:boolean){
    this.isLoading$.next(data);
  }

  public getLoading():boolean{
    return this.isLoading$.getValue();
  }


}
