import { Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { LoginGuard } from '../services/guards/login.guard';
import { LoginComponent } from './layouts/login/login/login.component';
import { PassRecoveryComponent } from './layouts/pass-recovery/pass-recovery.component';
import { PageGuard } from '../services/guards/page.guard';

export const AppRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full',
  },
  {
    path: 'recuperar-password',
    component: PassRecoveryComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    component: AppBlankComponent,
    children: [
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full',
      },
      {
        path: 'error',
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule),
      }
    ]
  },
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: 'inicio',
        loadChildren: () => import('./pages/inicio/inicio.module').then(m => m.InicioComponentModule),
        canActivate: [LoginGuard]
      },
      {
        path: '',
        loadChildren: () => import('./pages/inicio/inicio.module').then(m => m.InicioComponentModule),
        canActivate: [LoginGuard, PageGuard]
      },
      {
        path: 'consulta-pedidos',
        loadChildren: () => import('./pages/pedidos/pedidos.module').then(m => m.PedidosComponentModule),
        canActivate: [LoginGuard, PageGuard]
      },
      // {
      //   path: 'comercios',
      //   loadChildren: () => import('./pages/comercios/comercios.module').then(m => m.ComerciosModule),
      //   canActivate: [LoginGuard, PageGuard]
      // },
      // {
      //   path: 'usuarios',
      //   loadChildren: () => import('./pages/administradores/administradores.module').then(m => m.AdministradoresModule),
      //   canActivate: [LoginGuard, PageGuard]
      // },
      {
        path: 'puntos-de-entrega',
        loadChildren: () => import('./pages/puntos-de-entrega/puntos-de-entrega.module').then(m => m.PuntosDeEntregaModule),
        canActivate: [LoginGuard, PageGuard]
      },
      {
        path: 'listado-productos',
        loadChildren: () => import('./pages/articulos/articulos.module').then(m => m.ArticulosComponentModule),
        canActivate: [LoginGuard, PageGuard]
      },
      // {
      //   path: 'tipos-de-precios',
      //   loadChildren: () => import('./pages/tipos-de-precios/tipos-de-precios.module').then(m => m.TiposDePreciosModule),
      //   canActivate: [LoginGuard, PageGuard]
      // },
      {
        path: 'etiquetas',
        loadChildren: () => import('./pages/etiquetas/etiquetas.module').then(m => m.EtiquetasComponentModule),
        canActivate: [LoginGuard, PageGuard]
      },
      {
        path: 'categorias',
        loadChildren: () => import('./pages/categorias/categorias.module').then(m => m.CategoriasModule),
        canActivate: [LoginGuard, PageGuard]
      },
      {
        path: 'rubros',
        loadChildren: () => import('./pages/rubros/rubros.module').then(m => m.RubrosModule),
        canActivate: [LoginGuard, PageGuard]
      },
      {
        path: 'forma-de-pago',
        loadChildren: () => import('./pages/forma-de-pago/forma-de-pago.module').then(m => m.FormaDePagoModule),
        canActivate: [LoginGuard, PageGuard]
      },
      {
        path: 'modo-de-entrega',
        loadChildren: () => import('./pages/modo-de-entrega/modo-de-entrega.module').then(m => m.ModoDeEntregaModule),
        canActivate: [LoginGuard, PageGuard]
      },
      {
        path: 'vendedores',
        loadChildren: () => import('./pages/vendedores/vendedores.module').then(m => m.VendedoresModule),
        canActivate: [LoginGuard, PageGuard]
      },
      {
        path: 'clientes',
        loadChildren: () => import('./pages/clientes/clientes.module').then(m => m.ClientesModule),
        canActivate: [LoginGuard]
      },
      {
        path: 'aceptacion-clientes',
        loadChildren: () => import('./pages/clientes-nuevos/clientes-nuevos.module').then(m => m.ClientesNuevosModule),
        canActivate: [LoginGuard]
      },
      {
        path: 'sucursales',
        loadChildren: () => import('./pages/sucursales/sucursales.module').then(m => m.SucursalesModule),
        canActivate: [LoginGuard, PageGuard]
      },
      {
        path: 'configuracion-usuario',
        loadChildren: () => import('./pages/user-config/user-config.module').then(m => m.UserConfigComponentModule),
        canActivate: [LoginGuard]
      },
      {
        path: 'mensajes',
        loadChildren: () => import('./pages/mensajes/mensajes.module').then(m => m.MensajesComponentModule),
        canActivate: [LoginGuard]
      },
      {
        path: 'notificaciones',
        loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsComponentModule),
        canActivate: [LoginGuard]
      },
      {
        path: 'configuracion-comercio',
        loadChildren: () => import('./pages/configuracion-comercio/configuracion-comercio.module').then(m => m.ConfiguracionComercioModule),
        canActivate: []
      },
      {
        path: 'crear-ofertas',
        loadChildren: () => import('./pages/crear-oferta-pdf/crear-oferta-pdf.module').then(m => m.CrearOfertaPdfModule),
        canActivate: [LoginGuard]
      },
      {
        path: '**',
        redirectTo: '/login',
      },
    ],
  },

  /*{
    path: '**',
    redirectTo: 'error/404'
  }*/
];
