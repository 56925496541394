import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Pagination } from '../../../entities/base-api-response.entity';
import { ScrollsService } from '../../../services/scrolls/scrolls.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit,OnChanges {

  @Input() pagination:Pagination;
  @Output() changePage = new EventEmitter<any>();
  totalPaginationArray;
  activePage:number;
  perPage:number;
  limit:number = 4;
  offset:number = 4;


  constructor(private scrollService:ScrollsService) { }

  ngOnChanges() {
    this.initPaginations();
  }

  ngOnInit() {
    this.initPaginations();
  }

  initPaginations(){
    this.setTotalPaginationArray();
    this.setActivePage();
  }

  setTotalPaginationArray(){
    this.totalPaginationArray = Array(this.pagination.totalPages).map((x,i)=> i);
  }

  setActivePage(){
    this.activePage = this.pagination.page;
  }

  setPerPage(){
    this.perPage = this.pagination.perPage;
  }

  changePageNumber(pageNumber:number){
    this.changePage.emit(pageNumber);
    this.scrollService.setScrollContainer(true);
  }


}
