import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  AfterViewInit,
  ElementRef,
  ViewChild
} from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { OnInit } from '@angular/core';
import { ScrollsService } from '../../../services/scrolls/scrolls.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../../services/user/user.service';
import { IMAGES } from '../../constants/path';
import { ComerciosService } from 'services/comercios/comercios.service';
import { LocalstorageService } from 'services/localstorage/localstorage.service';
import { finalize, map } from 'rxjs/operators';
import { LoadingSpinnerService } from 'services/loading-spinner/loading-spinner.service';


/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: []
})
export class FullComponent implements OnDestroy, OnInit {
  mobileQuery: MediaQueryList;
  minisidebar: boolean;
  sidebarOpened;
  @ViewChild('contentContainer', { static: false }) contentContainer: any;
  private _mobileQueryListener: () => void;
  private subscriptions = new Subscription();
  public logoComercio: string;
  public logoAlCarrito: string;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private scrollService: ScrollsService,
    private userService: UserService,
    private comercioService: ComerciosService,
    public localStorageService: LocalstorageService,
    private loading:LoadingSpinnerService
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 1100px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    if (this.localStorageService.getLocalStorage('comercio')) {
      const comer = JSON.parse(this.localStorageService.getLocalStorage('comercio'));
      this.comercioService.setComercioData(comer);
    } else {
      this.loading.setLoading(true);
      this.getDataComercio();
    }
    this.subscriptions.add(this.scrollService.scrollToTop.subscribe(condition => {
      if (condition) {
        let scroll = this.contentContainer.elementRef.nativeElement;
        scroll.scrollTop = 0;
      }
    }))
    this.subscriptions.add(this.comercioService.comercioData$.subscribe(comercio => {
      if (comercio?.url_logo) {
        this.logoComercio = comercio.url_logo
      } else {
        this.logoComercio = this.userService.getLogo();
        if(this.logoComercio){
          this.logoComercio = this.logoAlCarrito;
        }
      }
    }))
  }

  private getDataComercio() {
    this.comercioService.getComercioConfig().pipe(
      map(com =>{
        return {
          comercio:com.comercio?.config
        }
      })
    ).pipe(finalize(() => this.loading.setLoading(false) )).subscribe(c => {
      this.comercioService.setComercioData(c.comercio);
      this.localStorageService.setLocalStorage('comercio', JSON.stringify(c.comercio));
    })
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.subscriptions.unsubscribe();
  }
  // ngAfterViewInit() { }
}
