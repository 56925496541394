import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../../../services/login/login.service';
import { Utils } from '../../../utils';
import { MatDialog } from '@angular/material/dialog';
import { IMAGES } from '../../../constants/path';
import { LABELS } from '../../../constants/labels';
import { LoadingSpinnerService } from '../../../../services/loading-spinner/loading-spinner.service';
import { UserService } from '../../../../services/user/user.service';
import { LocalstorageService } from '../../../../services/localstorage/localstorage.service';
import { PopUpsUtils } from '../../../pop-ups-utils';
import { AngularFireAuth } from '@angular/fire/auth';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit,OnDestroy {

  loginForm = new FormGroup({
    email: new FormControl(null, [Validators.required]),
    password: new FormControl(null, [Validators.required]),
  });

  emailLabel: string = 'Email';
  emailPlaceholder: string = 'Ingresa tu Email';
  passLabel: string = 'Contraseña';
  passPlaceholder: string = 'Ingresa tu Contraseña';
  errorInfo: string = 'Email o Contraseña Incorrectas.';
  // logoUrl: string = IMAGES.LOGOURL;
  // logoAlt: string = LABELS.LOGO.LOGO_ALT;
  logoUrl: string = `./assets/images/${environment.suscriber.dns}.svg`  
  logoAlt: string = `${environment.suscriber.title} logo`
  hide: boolean = true;
  isLogin: boolean = false;
  private subscriptions = new Subscription()

  constructor(
    private router: Router,
    public utils: Utils,
    private loginService: LoginService,
    public dialog: MatDialog,
    private loading: LoadingSpinnerService,
    private userService: UserService,
    private localStorageService: LocalstorageService,
    private popUpsUtils: PopUpsUtils,
    private afAuth: AngularFireAuth,
  ) { }

  ngOnInit() {
    this.loginService.getCurrentUser().then((user) => {
      if (user && user.email) {
        this.router.navigate(['inicio']);
      }
    });
  }

  ngOnDestroy(){
    this.subscriptions.unsubscribe();
  }

  public login() {
    this.loading.setLoading(true);
    const { email, password } = this.loginForm.value;
    if (email && password) {
      this.loginService.login(email, password).then(data => {
        if (data) {
          this.subscriptions.add(this.afAuth.idToken.take(1).subscribe(idToken => {
            if(!environment.production){
              console.log(idToken)
            }
            if (idToken) {
              this.subscriptions.add(this.userService.getUserSigninData().take(1).subscribe(user => {
                if (user && user.success) {
                  this.userService.setUserSignin(user);
                  this.localStorageService.setLocalStorage('userSignin', JSON.stringify(user));
                  this.router.navigate(['inicio']);
                } else {
                  this.popUpsUtils.popUpConfirmation('Hubo un error en el Logueo.', true);
                }
                this.loading.setLoading(false);
              }, (error) => {
                this.popUpsUtils.popUpConfirmation('Hubo un error en el Logueo.', true);
                this.loading.setLoading(false);
              }))
            }
          }, () => {
            this.popUpsUtils.popUpConfirmation('Hubo un error en el Logueo.', true);
            this.loading.setLoading(false);
          }))
        } else {
          this.loading.setLoading(false);
          this.loginForm.get('password').reset();
          this.popUpsUtils.popUpConfirmation('El Usuario o Contraseña es incorrecto.', true);
        }

      })
    }
  }


}
