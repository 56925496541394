import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IMAGES } from 'app/constants/path';

export interface itemGeneral{
  header:string,
  body:any,
  type:string,
}

@Component({
  selector: 'app-detalle-general',
  templateUrl: './detalle-general.component.html',
  styleUrls: ['./detalle-general.component.scss']
})

export class DetalleGeneralComponent implements OnInit {

  noPhoto = IMAGES.NOPRODUCTS;

  constructor(public dialogRef: MatDialogRef<DetalleGeneralComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog) { }

  ngOnInit() {

  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public setData(item: itemGeneral) {
    let data;
    if (item.type === "string") {
      data = item.body || '-';
    }
    if (item.type === "boolean") {
      if (item.body) {
        data = "Si"
      } else {
        data = "No"
      }
    }
    if (item.type === "img") {
      if (item.body) {
        data = `<img class="item-img img-block" src="${item.body}"/>`;
      } else {
        data = `<img class="item-img img-block" src="${this.noPhoto}"/>`
      }
    }
    return data;
  }

}
