import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AUTH_HEADER, AUTH_HEADER_FILE_UPDATE } from '../../app/constants/api.constant';
import { LoginService } from '../login/login.service';


@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(protected httpClient: HttpClient, protected loginService?: LoginService) { }

  // Momentáneamente no utilizar enviroment.baseUrl
  public get<T>(url: string): Observable<T> {
    return this.httpClient.get<T>(environment.baseUrl + url, {
      headers: AUTH_HEADER(this.loginService.idToken)
    });
  }

  public getDownload<T>(url: string, options?: any): Observable<T> {
    return this.httpClient.get<T>(environment.baseUrl + url, {
      headers: AUTH_HEADER(this.loginService.idToken)
    });
  }

  public authGet<T>(url: string, elem?: any): Observable<T> {
    return this.httpClient.get<T>(environment.baseUrl + url, { headers: AUTH_HEADER(this.loginService.idToken), params: elem });
  }

  public authPost<T>(url: string, body: any): Observable<T> {
    return this.httpClient.post<T>(environment.baseUrl + url, JSON.stringify(body), { headers: AUTH_HEADER(this.loginService.idToken) });
  }

  public post<T>(url: string, body: any): Observable<T> {
    return this.httpClient.post<T>(environment.baseUrl + url, JSON.stringify(body), {
      headers: AUTH_HEADER(this.loginService.idToken)
    });
  }

  public postFile<T>(url: string, body: FormData): Observable<T> {
    return this.httpClient.post<T>(environment.baseUrl + url, body,{
      headers: AUTH_HEADER_FILE_UPDATE(this.loginService.idToken)
    });
  }

  public update<T>(url: string, body: any): Observable<T> {
    return this.httpClient.post<T>(environment.baseUrl + url, JSON.stringify(body), {
      headers: AUTH_HEADER(this.loginService.idToken)
    });
  }

  public put<T>(url: string, body: any): Observable<T> {
    return this.httpClient.put<T>(environment.baseUrl + url, JSON.stringify(body), {
      headers: AUTH_HEADER(this.loginService.idToken)
    });
  }

  public delete<T>(url: string): Observable<T> {
    return this.httpClient.delete<T>(environment.baseUrl + url, {
      headers: AUTH_HEADER(this.loginService.idToken)
    });
  }




}
