import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent implements OnInit {

  errorClass:boolean = false;

  constructor(public dialogRef: MatDialogRef<ConfirmationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
     }

  ngOnInit() {
    if(this.data.error){
      this.errorSet();
    }
    this.closeModal();
  }

  public errorSet(){
    if(!this.data.text){
      this.data.text = 'Ha sucedido un error.';
    }
      this.data.icon = 'close';
      this.errorClass = true;
  }

  private closeModal(){
    setTimeout(() => {
      this.dialogRef.close();
    }, (this.data.timeToClose ? this.data.timeToClose : 2000));
  }

}
