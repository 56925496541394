import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { LoginService } from '../login/login.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})


export class PageGuard implements CanActivate {


  constructor(public loginService: LoginService, public router: Router, private userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let url = state.url.split('/')[state.url.split('/').length - 1];
    let functions = this.userService.getFunctions();
    let conditional: boolean = false;
    if (functions) {
      functions.forEach(f => {
        if (f.child && f.child.length) {
          f.child.forEach(fc => {
            if (fc.url === url) {
              conditional = true;
            }
          })
        } else {
          if (f.url === url) {
            conditional = true;
          }
        }
      })
      return conditional;
    } else {
      this.router.navigate(['/login'])
    }
  }


}
