import { environment } from '../../environments/environment';
export const IMAGES = {
  GLOBAL:'assets/images',
  ICONS_DEFAULT:'assets/images/icons-default',
  NOPRODUCTS:'assets/images/articulos/sin_foto.jpg',
  LOGOURL:'assets/images/alcarrito2.svg',
  LOGOURL2:'assets/images/alcarrito3.svg',
  LOADING:'assets/images/loading.gif'
}

