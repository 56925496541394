import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {

  constructor() { }

  public setLocalStorage(parameter:string,value:any){
    localStorage.setItem(parameter,value);
  }

  public getLocalStorage(parameter:string){
    return localStorage.getItem(parameter);
  }

  public deleteLocalStorage(parameter:string){
    localStorage.removeItem(parameter);
  }

  public resetAll(){
    localStorage.clear();
  }


}
