import { API_ENDPOINTS_USER_SYSTEM } from './../../app/constants/api.constant';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { HttpService } from '../http/http.service';
import { LoginService } from '../login/login.service';
import { User, SigninUser, Functions, Suscriptor } from '../../entities/user.entity';
import { API_ENDPOINTS_USERS } from '../../app/constants/api.constant';
import { BehaviorSubject } from 'rxjs';
import { LocalstorageService } from '../localstorage/localstorage.service';
import { Router, Data } from '@angular/router';
import { TYPE_USER } from 'app/enums/type-user';
// import { HARDFUNCTIONS } from 'app/shared/hard-functions.module';

@Injectable({
  providedIn: 'root'
})
export class UserService extends HttpService implements OnInit {

  private userId: number;
  public userSignin$: BehaviorSubject<SigninUser> = new BehaviorSubject(null);
  public idSuscriptor;

  constructor(
    protected http: HttpClient,
    protected auth: LoginService,
    private localStorageService: LocalstorageService,
    public router: Router
  ) {
    super(http, auth);
    if (this.localStorageService.getLocalStorage('userSignin')) {
      this.setUserSignin(JSON.parse(this.localStorageService.getLocalStorage('userSignin')));
    }
  }

  ngOnInit() { }

  public getUserData() {
    return this.get<{ user: User }>(API_ENDPOINTS_USERS.USERS + '/' + this.userId).map(data => data.user);
  }

  // get datos del usuario

  public getUserSigninData() {
    return this.post<any>(API_ENDPOINTS_USERS.USERS_SYSTEM_SIGNIN, {});
  }

  public getUserSystem(id: number){
    return this.get<any>(API_ENDPOINTS_USER_SYSTEM(id));
  }

  public updateUserSystem(id: number, body: any){
    let data = {
      user: body
    }
    return this.put<any>(API_ENDPOINTS_USER_SYSTEM(id), data);
  }

  public getUserLogginData(): SigninUser {
    return this.userSignin$.getValue();
  }

  public setUserSignin(user: SigninUser) {
    if (user) {
      this.userSignin$.next(user);
    }
  }

  // Nombre

  public getNameUser(): string {
    if (this.userSignin$.getValue().userData.user.user_name) {
      return this.userSignin$.getValue().userData.user.user_name;
    } else {
      return this.userSignin$.getValue().userData.user.user_email;
    }
  }

  // ID

  public getIdUser(): number {
    return this.userSignin$.getValue().userData.user.id;
  }

  // ID FIREBASE

  public getIdFirebase(): string {
    return this.userSignin$.getValue().userData.user.id_firebase;
  }

  // suscriptor

  public getIdSuscriptor(): number {
    return this.userSignin$.getValue().userData.suscriptor.id;
  }

  public getSuscriptorData(): any {
    return this.userSignin$.getValue().userData.suscriptor;
  }
  // comercio

  // public getIdComercio(): number {
  //   return this.userSignin$.getValue().userData.user.id_comercios;
  // }

  // rubro comercial

  public getIdRubroComercial(): number {
    return this.userSignin$.getValue().userData.user.id_comercios;
  }

  // sucursal

  public getIdSucursal(): number {
    return this.userSignin$.getValue().userData.user.id_sucursales;
  }

  // functions

  public getFunctions(): Functions[] {
    if (this.userSignin$.getValue() && this.userSignin$.getValue().userData.functions) {
      // console.log(this.userSignin$.getValue().userData.functions)
      return this.userSignin$.getValue().userData.functions;
    }
  }

  // suscriptor logo

  public getLogo(): string {
    if (this.userSignin$.getValue() && this.userSignin$.getValue().userData.suscriptor) {
      return this.userSignin$.getValue().userData.suscriptor.url_logo;
    }
  }

  // getUrlType

  public getTypeUserUrl(forceChild?: string): string {
    let url = this.router.url.split('/')[1];
    let completeUrl = '/' + url;
    let functions = this.getFunctions();
    // let functions = HARDFUNCTIONS;
    console.log(this.userSignin$.getValue().userData.functions)
    let func = functions.find(f => {
      let data;
      if (f.url === url) {
        data = f.url;
      }
      if (f && f.child.length) {
        data = f.child.find(c => c.url === url);
      }
      return data;
    });
    let type: string = TYPE_USER.C;
    if (func && func.child.length > 0) {
      func.child.forEach(fc => {
        if (fc.url === url) {
          if ((this.router.url === completeUrl) || forceChild) {
            if (fc.type.length === 1) {
              type = fc.type;
            }
          }
        }
      })
    } else {
      if (func.type.length === 1) {
        type = func.type;
      }
    }
    return type;
  }

  public canEdit(type: string): boolean {
    if (type === TYPE_USER.A || type === TYPE_USER.B) {
      return true;
    }
    return false;
  }

  public canDelete(type: string): boolean {
    if (type === TYPE_USER.A) {
      return true;
    }
    return false;
  }
}
