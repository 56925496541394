import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from '../../../../services/user/user.service';
import { LoginService } from '../../../../services/login/login.service';
import { Router } from '@angular/router'
//import { CrearOfertaPdfComponent } from 'app/components/crear-oferta-pdf/crear-oferta-pdf.component';
import { MatDialog } from '@angular/material/dialog';
import { POPUPS_ACTIONS } from 'app/constants/utils.constant';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  public userName: string;
  public email: string;
  public emailAsName: string;
  public role: string = 'Usuario';
  public userLetter: string;
  public isMsgPage: boolean = false;
  public isOfertaPage: boolean = false;
  public isNotificationsPage: boolean = false;
  public openId: number;
  private subscriptions = new Subscription();


  constructor(private userService: UserService,
    private loginService: LoginService,
    public router: Router,
    public dialog: MatDialog) {
    this.checkAllPages();
  }

  ngOnInit() {
    this.userService.userSignin$.subscribe(signin => {
      if (signin) {
        this.userName = signin.userData.user.user_name;
        console.log(signin.userData.user)
        this.email = signin.userData.user.user_email;
        let email_analizado = /^([^]+)@(\w+).(\w+)$/.exec(this.email);
        let [, nombre, servidor, dominio ] = email_analizado;
        this.emailAsName = nombre;
        this.setFirstLetter();
      }
    })
    this.subscriptions.add(this.router.events.subscribe(val => {
      this.checkAllPages();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private checkAllPages(): void {
    this.checkMsgPage();
    this.checkOfertaPage();
    this.checkNotificationsPage();
  }

  public checkMsgPage(): void {
    (this.router.url === '/mensajes') ? this.isMsgPage = true : this.isMsgPage = false;
  }

  public checkOfertaPage(): void {
    (this.router.url === '/crear-ofertas') ? this.isOfertaPage = true : this.isOfertaPage = false;
  }

  public checkNotificationsPage(): void {
    (this.router.url === '/notificaciones') ? this.isNotificationsPage = true : this.isNotificationsPage = false;
  }

  public logout() {
    this.loginService.logout();
  }

  public setFirstLetter(): void {
    if (this.userName) {
      this.userLetter = this.userName.charAt(0).toUpperCase();
    } else {
      this.userLetter = this.email.charAt(0).toUpperCase();
    }
  }

}
