import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { LoginService } from '../login/login.service';
import { UserService } from '../user/user.service';
import { HttpService } from 'services/http';
import { API_ENDPOINTS_FILES_UPLOAD } from '../../app/constants/api.constant';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXTENSION = '.csv';

export enum Folders {
  categorias = 'categorias',
  productos = 'productos',
  comercios = 'comercios',
  rubros = 'rubros',
  suscriptor = 'suscriptor',
  modoEntrega = 'modo-de-entrega',
  etiquetas = 'etiquetas',
  commons = 'commons',
  formaDePago = 'forma-de-pago',
}

@Injectable()
export class FilesDownloader extends HttpService {

  idSuscriptor: number = this.userSignin.getIdSuscriptor();

  constructor(protected http: HttpClient,
    protected auth: LoginService,
    protected userSignin: UserService) {
    super(http, auth)
  }


  public fileUpload(folder: string, file: any) {
    return this.postFile<{ fileUrl: string, success: boolean }>(API_ENDPOINTS_FILES_UPLOAD(this.idSuscriptor, folder), file);
  }

  // para importación de productos y precios
  public fileProductsUpload(folder: string, file: any) {
    return this.postFile<{ filesUrl: string, success: boolean }>(API_ENDPOINTS_FILES_UPLOAD(this.idSuscriptor, folder), file);
  }

  // tslint:disable-next-line: comment-format
  //para importación de imágenes
  public fileImageUpload(folder: string, file: any) {
    return this.postFile<{ filesUrl: string, success: boolean }>(API_ENDPOINTS_FILES_UPLOAD(this.idSuscriptor, folder), file);
  }

  public exportAsCSVFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'csv', type: 'array' });
    this.saveAsCSVFile(excelBuffer, excelFileName);
  }

  public saveAsCSVFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_encuesta_' + moment(new Date()).format("YYYY-MM-DD HH:mm:ss") + EXTENSION);
  }
}

